.col{
    margin:0 auto;
    text-align:center;
}
.yt-video{
    display:inline-block;
    margin:0.5rem;
    max-width:95% !important;
    width:560px;
    height:315px;
    border-radius:0.75rem;
    position:relative;
}