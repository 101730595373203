:root{
    --image-mg:0.25rem;
}
.mosaic-gallery{
    width:100%;
    padding:2rem 0;
    text-align:center;
}
.mosaic-image-container{
    margin:0 var(--image-mg) var(--image-mg) var(--image-mg);
    border-radius:0.25rem;
    padding:0;
    cursor:pointer;
    display:inline-block;
    overflow:hidden;
    width:256px;
    height:256px;
}
@media (max-width: 991px) {
    .mosaic-image-container{
        width:128px;
        height:128px;
    }
}
.mosaic-image{
    width:100%;
    min-height:100%;
    object-fit:cover;
    transition:transform 0.75s cubic-bezier(.17,0,0,1);
}
.mosaic-image:hover{
    transform:scale(1.1);
}
