:root{
    --panorama-width:20rem;
}
.pnlm-container{
    width:100% !important;
    height:calc(var(--panorama-width) * calc(2 / 3)) !important;
}
div:not(.info-row-kep) .panorama-container{
    display:inline-block;
    text-align:center;
    position:relative;
    max-width:95%;
    width:var(--panorama-width);
    margin:0.5rem;
    border-radius:0.75rem;
    box-shadow:0 0 0.5rem rgba(0,0,0,0.5);
}
div:not(.extra-container) .panorama-container{
    overflow:hidden;
}
.extra-container{
    overflow:hidden;
    border-radius:0.75rem;
}
.panorama-overlay{
    position:absolute;
    background-color:black;
    background-image:url('./assets/makerspace.jpg');
    background-size: cover;
    background-position: 0;
    width:100%;
    height:100%;
    z-index:1;
    cursor:pointer !important;
    transition:background-color 0.25s;
    display:none;
}
.panorama-overlay:hover{
    background-color:rgba(0,0,0,0.65);
}
.pnlm-ui > div:not(.pnlm-sprite):not(.pnlm-controls-container){
    background:transparent;
}
.pnlm-ui p{
    text-align:center;
}
.pnlm-load-button{
    top:0 !important;
    left:0 !important;
    width:100% !important;
    height:100% !important;
    margin:0 !important;
}
.pnlm-load-button-content{
    width:100%;
    height:calc(100% + 2px);
    color:white;
    background-size:cover;
    background-position:0;
    display:flex;
    position:absolute;
    z-index:10;
    transition:transform 0.25s;
}
.pnlm-load-button-content:hover{
    transform:scale(1.02);
}
.pnlm-load-button-content p{
    text-align:center !important;
    width:100%;
    margin:0;
    font-size:105%;
    font-weight:bold;
    align-self:center;
    text-shadow:0 0 0.5rem rgba(0,0,0,1);
    z-index:20;
    transform:scale(0.95);
    transition:transform 0.5s;
}
.pnlm-load-button-content:hover p{
    transform:scale(1.0);
}
.pnlm-about-msg{
    /* display:none !important; */
}
.pnlm-controls-container{
    right:8px !important;
    left:auto !important;
}
.pnlm-controls{
    background-color:rgba(255,255,255,0.5) !important;
    border:0 !important;
    width:48px !important;
    height:48px !important;
    margin-top:8px !important;
    border-radius:8px;
    opacity:0.75;
    transition:opacity 0.2s;
}
.pnlm-controls:hover{
    opacity:1.0;
}
.pnlm-sprite{
    background-image:url('./assets/icons.png') !important;
    background-repeat: no-repeat;
}
.pnlm-fullscreen-toggle-button-inactive{
    background-position:0 -96px !important;
}
.pnlm-fullscreen-toggle-button-active{
    background-position:0 -144px !important;
}
.pnlm-orientation-button-inactive{
    background-position:0 -288px !important;
}
.pnlm-orientation-button-active{
    background-position:0 -336px !important;
}
