.react-card-flip-container{
    width:250px;
    height:350px;
    margin:0.75rem;
    cursor:pointer;
    border-radius: 1rem;
    display:inline-block;
    transition:transform 0.25s;
}
@media (min-width: 992px) {
    .react-card-flip-container:hover{
        transform:scale(1.05);
    }
}
@media (max-width: 991px) {
    .react-card-flip-container:active{
        transform:scale(1);
    }
}
.react-card-flip{
    height:100%;
}
.react-card-front, .react-card-back{
    background-size: 100% 100%;
    color:black !important;
    border-radius: 1rem;
    overflow:hidden;
    box-shadow:0 0 0.75rem rgba(0,0,0,0.75);
}
