:root{
    --carouselHeight: 75vh;
}
.carousel{
    width:100vw;
    height:var(--carouselHeight);
    overflow:hidden;
}
.carousel-inner{
    background-color:black;
    height:100%;
}
.carousel-item img{
    width:100%;
    height:var(--carouselHeight);
    object-fit: cover;
}
.carousel-item{
    backface-visibility: visible;
    height:100%;
}
.carousel-item-left a, .carousel-item-right a{
    transition:none;
}
.carousel-item h2{
    font-size:3rem;
    margin-bottom:0.125rem;
}
@media (max-width: 991px) {
    .carousel-item h2{
        font-size:2.5rem;
    }
}
@media (max-width: 767px) {
    .carousel-item h2{
        font-size:2rem;
    }
}
.carousel-caption{
    padding:0.75rem 1.5rem !important;
    background-color:rgba(0,0,0,0.65);
    border-radius: 1rem;
    left:50% !important;
    right:auto !important;
    top:50% !important;
    bottom:auto !important;
    transform:translate(-50%,-50%);
}
.carousel-caption span{
    text-align:justify;
    text-align-last:center;
}
.carousel-control-prev, .carousel-control-next{
    background-color:rgba(0,0,0,0.3);
    transition:transform 0.2s, background-color 0.2s, opacity 0.2s !important;
    opacity:0.5 !important;
}
@media (min-width: 992px) {
    .carousel-control-prev:hover, .carousel-control-next:hover{
        background-color:rgba(0,0,0,0.3);
        transform:scale(1.25);
        opacity:0.9 !important;
    }
}
.carousel-control-prev:active, .carousel-control-next:active{
    background-color:rgba(0,0,0,0.3);
    transform:scale(1.15);
    opacity:0.9 !important;
}
.carousel-indicators{
    height:30px;
}
.carousel-indicators li{
    position:relative;
    transition:height 0.2s, opacity 0.2s !important;
}
.carousel-indicators li:hover{
    height:6px;
    opacity:0.85;
}
.carousel-indicators li.active{
    height:12px;
}
.ind-tt{
    position:absolute;
    font-weight:bold;
    margin:0;
    bottom:1rem;
    left:-60px;
    min-width:150px;
    z-index: 100;
    text-indent:0;
    text-align:center;
    color:white;
    background-color:rgba(0,0,0,0.4);
    border-radius:0.25rem;
    padding:0.125rem 0.325rem;
    opacity:0.0;
    transition:opacity 0.25s;
    pointer-events: none;
}
.ind-tt-act .ind-tt{
    opacity:1.0;
}
.read-more{
    position:relative;
    padding:0.15rem 0.3rem;
}
.read-more::after{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:0%;
    height:100%;
    border-radius:0.125rem;
    z-index:0;
    opacity:0.0;
    transition:width 0.25s, opacity 0.5s;
}
a:not(.darklink) .read-more::after{
    background-color:rgba(255,255,255,0.35);
}
a.darklink .read-more::after{
    background-color:rgba(0,0,0,0.25);
}
.read-more:hover::after{
    opacity:1.0;
    width:100%;
    transition:width 0.25s, opacity 0.05s;
}
