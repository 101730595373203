.info-row{
    overflow:hidden;
}
.info-row-szoveg:not(.m-0){
    margin:0.5rem 2rem !important;
}
.info-row-szoveg p{
    margin:0;
    text-align:justify;
}
.info-row-kep{
    margin:1rem;
    border-radius:0.5rem;
	background-repeat: no-repeat;
	background-size:cover;
	background-position: center;
    min-height:40vh;
}
.info-row-panorama{
    padding:0 !important;
}
.info-row-panorama .panorama-container{
    margin:0 !important;
}
.info-row-panorama .panorama-container, .info-row-panorama .pnlm-container{
    width:100% !important;
    height:100% !important;
}
.info-row-kozep{
    text-align:center;
}
.info-row-kozep:not(.m-0){
    margin:2rem 0.5rem !important;
}
.info-row-click{
    background-color:red;
    cursor:pointer;
    transition:opacity 0.25s;
}
.info-row-click:hover{
    opacity:0.8 !important;
}
.sidebyside{
    max-width: 1200px;
    margin:0 auto !important;
}
.sidebyside-margins{
    max-width: 1200px;
    margin:2rem auto !important;
}
.fade-int{
    opacity:0;
}
.reverse-order .info-row-szoveg{
    order:2;
}
.reverse-order .info-row-kep{
    order:1;
}
.moretext{
    font-weight: bold;
}
.quote, .quote-author{
    font-weight: bold;
    text-shadow: 0 0 5px rgba(255,255,255,1);
}
.quote{
    font-size:110%;
}
.quote-author{
    font-size:120%;
}
.logo-container{
    width:100%;
    height:100%;
}
.logo-container .row{
    height:50%;
}
.logo-container .top-row{
    align-self:flex-end !important;
}
.logo-container .bottom-row{
    align-self:flex-start !important;
}
.logo-container .col{
    padding:0.25rem 0.5rem;
}
.logo-container p{
    margin:0;
}
.f-logo{
    cursor:pointer;
    max-width:100%;
    max-height:100%;
    transition:opacity 0.25s;
}
.f-logo:hover{
    opacity:0.75;
}
@media (min-width: 1600px) {
    .sidebyside{
        max-width: 1500px !important;
    }
    .sidebyside-margins{
        max-width: 1500px !important;
    }
}
@media (max-width: 991px) {
    .sidebyside:not(.eq-sep) .info-row-szoveg{
        margin:1rem 0.5rem 0.5rem 0.5rem !important;
    }
    .sidebyside.eq-sep .info-row-szoveg p{
        margin-bottom:0.75rem !important;
    }
    .sidebyside .info-row-kep{
        margin-top:0.25rem !important;
    }
    mobile-wrapper{
        background-color:red;
    }
    .info-row-kozep:not(.m-0){
        margin:2rem 2rem !important;
    }
    .reverse-order .info-row-szoveg{
        order:1;
    }
    .reverse-order .info-row-kep{
        order:2;
    }
    .info-row-szoveg h2{
        text-align:center;
    }
    .info-row-szoveg p{
        text-align-last:center;
    }
}
